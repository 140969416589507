/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { getBrand } from '../../../pages/Account/state/ducks/Common/Common-Selectors';

const GraphqlSameDayCollectionSchema = () => {
    const brand = useSelector(getBrand);
    const telephone = '(###) ###-####';
    const description = '';
    const image = '';

    return (
        <Helmet>
            <script type="application/ld+json">
                {`{
          "@context": "https://schema.org",
          "@type": "Florist",
          "name": "${brand.domain}",
          "description": "${description}",
          "image":"${image}",
          "URL": "${`https://www.${brand.domain}.com`}",
          "telephone": "${telephone}",
          "areaServed": "United States",
          "contactPoint": [
            {
              "@type": "ContactPoint",
              "telephone": "${telephone}",
              "contactType": "customer service"
            }
          ]
        }`}
            </script>
        </Helmet>
    );
};

export default GraphqlSameDayCollectionSchema;
