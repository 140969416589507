/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    object, bool, arrayOf, shape, number, string, array, func,
} from 'prop-types';
import CloseCircleIcon from '../../../../BrandTheme/Icons/CloseCircleIcon';

const styles = (theme) => ({
    chipsContainer: {
        overflowX: 'auto',
        overflowY: 'hidden',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        gap: '8px',
        backgroundColor: 'white',
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '10px',
            backgroundColor: 'rgba(156, 156, 156, 1)',  // scroll bar color
        },
        '&::-webkit-scrollbar': {  /* Works on scrollbar for Chrome, Safari and Opera */
            height: '3px',
            backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-track': {
            borderRadius: '10px',
            backgroundColor: 'transparent',
        },
    },
    modalChipsContainer: {
        minHeight: '62px',
        padding: '10px 0 8px 0',
        marginRight: '13px',
        marginLeft: '20px',
        [theme.breakpoints.down(415)]: {
            marginLeft: '13px',
            paddingBottom: '14px',
        },
    },
    mainViewChipsContainer: {
        padding: '0 0 15px',
        boxShadow: 'rgb(255 255 255) 5px -5px 0px 5px',
    },
    filterChip: {
        display: 'flex',
        backgroundColor: theme.palette.highlightColor || '#F5F5F5',
        padding: '9px 10px 6px 12px',
        borderRadius: '20px',
        fontSize: '14px',
        whiteSpace: 'nowrap',
        '& svg': {
            margin: '0 0 0 10px',
            cursor: 'pointer',
        },
    },
    chipCloseBtn: {
        border: 'none',
        padding: 0,
        margin: 0,
        backgroundColor: 'transparent',
        lineHeight: 1,
    },
    clearAll: {
        border: 'none',
        backgroundColor: 'transparent',
        textDecoration: 'underline',
        cursor: 'pointer',
        fontSize: '14px',
        [theme.breakpoints.down(1025)]: {
            display: 'none',
        },
    },
});

/**
 * @description - Chips are the pill shaped labels that show active filters are are in a horizontally scrolling container
 * @return {jsx}
 */

const FilterChipsUI = ({
    isInModal, classes, productFacetsBase, checkedOptions, setCheckedOptions, resetChecked,
}) => {
    const unapplyFilter = (chipValue) => {
        const newCheckedOptions = checkedOptions.filter((facetString) => facetString !== chipValue);
        setCheckedOptions(newCheckedOptions);
    };

    // create array of {names, values} of checked facet options to display in chips
    const checkedFilterNames = [];
    const facetsDataAsArray = productFacetsBase ? Object.values(productFacetsBase) : [];
    if (checkedOptions) {
        checkedOptions.forEach((checkedFilter) => {
            facetsDataAsArray.forEach((facet) => {
                facet.entries.filter((filterOption) => {
                    if (filterOption.value === checkedFilter) {
                        checkedFilterNames.unshift({ name: filterOption.name, value: checkedFilter });
                    }
                });
            });
        });
    }

    if (checkedFilterNames.length) {
        return (
            <div className={`${classes.chipsContainer} ${isInModal ? classes.modalChipsContainer : classes.mainViewChipsContainer}`}>
                {checkedFilterNames.map((filterObj) => (
                    <span className={classes.filterChip}>
                        {filterObj.name}
                        <button data-testid={`remove-${filterObj.value}`} aria-label={`Remove ${filterObj.value}`} className={classes.chipCloseBtn} type="button" onClick={() => unapplyFilter(filterObj.value)}>
                            <CloseCircleIcon fill="#2F2F2F" />
                        </button>
                    </span>
                ))}
                <button data-testid="clear-all-button" type="button" aria-label="Clear All Filters" className={classes.clearAll} onClick={resetChecked}>clear all</button>
            </div>
        );
    }
    return null;
};

FilterChipsUI.propTypes = {
    isInModal: bool,
    classes: object.isRequired,
    productFacetsBase: arrayOf(shape({
        name: string,
        entries: arrayOf(shape({
            count: number,
            from: number,
            name: string,
            value: string,
        })),
    })),
    checkedOptions: array.isRequired,
    setCheckedOptions: func.isRequired,
    resetChecked: func.isRequired,
};
FilterChipsUI.defaultProps = {
    isInModal: false,
    productFacetsBase: [],
};
export default withStyles(styles)(FilterChipsUI);
